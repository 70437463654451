var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.levels),function(level,index){return _c('div',{key:index},[_c('h5',[_vm._v(_vm._s(_vm.$t("resources.journeys.level"))+" "+_vm._s(index + 1))]),_c('v-list',{attrs:{"dense":"","color":"transparent","min-height":"80px"}},[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"list":level.bundles,"group":"bundles","disabled":_vm.loading ||
          _vm.journey.status !== 'unpublished' ||
          !['superadmin', 'admin', 'editor'].includes(_vm.role),"empty-insert-threshold":44},on:{"start":function($event){_vm.isDragging = true},"end":function($event){_vm.isDragging = false;
          _vm.changeOrder();}}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"list"}},_vm._l((level.bundles),function(bundle){return _c('v-sheet',{key:bundle.id,attrs:{"elevation":_vm.journey.status === 'unpublished' ? 1 : 0}},[_c('v-list-item',{staticClass:"mb-2 sorter-list-item",class:{
                inactive:
                  _vm.journey.status !== 'unpublished' ||
                  !['superadmin', 'admin', 'editor'].includes(_vm.role),
              },attrs:{"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-swap-vertical-bold ")])],1),_c('v-list-item-content',[_vm._v(_vm._s(bundle.name))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"text":"","x-small":"","to":{
                    name: 'JourneyBundle',
                    params: { bundle_id: bundle.id },
                  }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)}),1)],1)],1)],1)}),(['superadmin', 'admin'].includes(_vm.role))?_c('v-btn',{attrs:{"x-small":"","depressed":"","color":"primary","disabled":!['unpublished'].includes(_vm.journey.status) ||
      _vm.journey.bundles.length == 0 ||
      _vm.levels.length > _vm.bundleLevelCount},on:{"click":_vm.addLevel}},[_vm._v(" "+_vm._s(_vm.$t("resources.journeys.addLevel"))+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }