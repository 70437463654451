<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-form v-model="valid">
        <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
          <v-card-title class="overline secondary--text">
            <span>{{
              content_ori
                ? $t("gw.addPart.duplicatePart")
                : $t("gw.addPart.newPart")
            }}</span>
            <v-spacer />
            <v-btn v-if="false" x-small depressed disabled color="secondary">
              {{ $t("gw.modes.bulk") }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <InputTextarea
                  v-model="content"
                  :label="$t('resources.nuggets.content')"
                  dense
                  :rules="rules.content"
                  listen-to-input
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="options.nuggets"
                  dense
                  inset
                  class="ml-1"
                  color="secondary"
                >
                  <template #label>
                    {{ $t("gw.addSession.createNuggets") }}
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="options.questions"
                  dense
                  inset
                  class="ml-1"
                  color="secondary"
                >
                  <template #label>
                    {{ $t("gw.addSession.createQuestions") }}
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="12">
                <v-alert
                  v-show="wordCount <= maxWords"
                  type="info"
                  color="secondary"
                  text
                >
                  <span
                    class="caption"
                    v-html="
                      $t('gw.creditInfo', {
                        words: wordCount.toLocaleString(),
                        credits: creditCost.toLocaleString(),
                        balance:
                          licences.ghostwriter.credits_count.toLocaleString(),
                      })
                    "
                  />
                </v-alert>
                <v-expand-transition>
                  <v-alert
                    v-show="
                      (!licences.ghostwriter.licence.can_overload &&
                        creditCost > licences.ghostwriter.credits_count) ||
                      (licences.ghostwriter.licence.can_overload &&
                        creditCost >
                          licences.ghostwriter.credits_count +
                            licences.ghostwriter.licence.credits_overload)
                    "
                    type="error"
                    text
                    dense
                  >
                    <span class="caption">{{
                      $t("gw.not_enough_credits")
                    }}</span>
                  </v-alert>
                </v-expand-transition>
                <v-expand-transition>
                  <v-alert
                    v-show="
                      licences.ghostwriter.licence.can_overload &&
                      creditCost > licences.ghostwriter.credits_count &&
                      creditCost <=
                        licences.ghostwriter.credits_count +
                          licences.ghostwriter.licence.credits_overload
                    "
                    color="secondary"
                    icon="mdi-bank"
                    text
                    dense
                  >
                    <span class="caption">{{
                      $t("gw.licences.credit_pool_usage_hint")
                    }}</span>
                  </v-alert>
                </v-expand-transition>
                <v-expand-transition>
                  <v-alert
                    v-show="wordCount > maxWords"
                    color="error"
                    icon="mdi-alert"
                    text
                    dense
                  >
                    <div class="caption">
                      {{ $t("gw.too_many_words_hint_2") }}
                    </div>
                    <v-btn
                      x-small
                      depressed
                      :to="{ name: 'GwSessionBulk' }"
                      class="mt-2"
                      >{{ $t("gw.too_many_words_hint_2_btn") }}</v-btn
                    >
                  </v-alert>
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
            <v-btn
              text
              color="success"
              small
              :loading="loading"
              :disabled="
                content.length === 0 ||
                loading ||
                !valid ||
                (!licences.ghostwriter.licence.can_overload &&
                  creditCost > licences.ghostwriter.credits_count) ||
                (licences.ghostwriter.licence.can_overload &&
                  creditCost >
                    licences.ghostwriter.credits_count +
                      licences.ghostwriter.licence.credits_overload)
              "
              @click="addPart()"
            >
              {{ $t("general.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "GwAddPartDialog",
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
    inputOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      valid: false,
      content: "",
      content_ori: "",
      options: {
        nuggets: true,
        questions: true,
      },
      loading: false,
      maxWords: 350,
      rules: {
        content: [
          (v) =>
            (!!v && v.length > 64) ||
            this.$t("gw.too_few_chars", { min: 64, current: v ? v.length : 0 }),
          () =>
            this.wordCount <= this.maxWords ||
            this.$t("gw.too_many_words", {
              max: this.maxWords,
              current: this.wordCount,
            }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("auth", { licences: "get_licences" }),
    wordCount() {
      if (!this.content || this.content.length === 0) return 0;
      return this.content.split(" ").length - 1;
    },
    creditCost() {
      let multiplier =
        (this.options.nuggets ? 1 : 0) + (this.options.questions ? 3 : 0);
      return this.wordCount * multiplier;
    },
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.resetContent();
      }
      if (!val) this.reset();
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["add_part", "generate_content"]),
    ...mapMutations("app", ["set_loading"]),
    ...mapMutations("auth", ["set_gw_credits"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    async addPart() {
      this.set_loading(true);
      this.loading = true;
      //await new Promise(resolve => setTimeout(resolve, 5000));
      let payload = {
        content: this.content,
        session_id: this.sessionId,
      };
      let res = await this.add_part({
        payload: payload,
      });
      let res_gen;

      if (!res)
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      if (this.options.nuggets || this.options.questions) {
        let payload_gen = {
          part_id: res.part_id,
          session_id: this.sessionId,
          //options: this.options,
          language: this.options.language,
          nuggets: this.options.nuggets,
          questions: this.options.questions,
        };
        res_gen = await this.generate_content({
          payload: payload_gen,
        });
        if (res.gen)
          this.set_gw_credits(
            this.licences.ghostwriter.credits_count - res_gen.credits
          );
      }

      this.set_loading(false);
      this.loading = false;
      if (!res && !res_gen) {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      } else {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.closeDialog();
      }
    },
    reset() {
      this.resetContent();
    },
    resetContent() {
      this.content = this.content_ori ? this.content_ori : "";
      this.options = this.inputOptions
        ? { ...this.inputOptions }
        : { nuggets: true, questions: true };
    },
    openDialog(text) {
      this.content_ori = text;
      this.showDialog = true;
    },
  },
};
</script>
