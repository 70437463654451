<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-alert
          v-if="!licences.ghostwriter || licenceExpired"
          text
          type="error"
          dense
          border="left"
        >
          <span class="caption">{{ $t("gw.no_licence") }}</span>
        </v-alert>
        <v-row>
          <v-col cols="12">
            <v-data-table
              id="gw-sessions"
              class="grey lighten-3"
              :headers="headers"
              :items="sessions"
              :items-per-page="10"
            >
              <template #item="{ item }">
                <tr
                  class="hover"
                  :class="{ invalid: item.disabled }"
                  @click="goToDetails(item)"
                >
                  <td>
                    <v-tooltip right color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex align-center"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            v-if="![1, 2, 6].includes(item.status)"
                            :color="item.status_color"
                            small
                          >
                            {{ item.status_icon }}
                          </v-icon>
                          <v-progress-circular
                            v-if="[1, 2, 6].includes(item.status)"
                            indeterminate
                            color="primary"
                            size="16"
                            width="2"
                          />
                        </div>
                      </template>
                      <span>{{
                        item.disabled
                          ? $t("gw.invalid_session")
                          : $t(`gw.status.${item.status}`)
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip v-if="!item.disabled" right color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar tile size="24" v-bind="attrs" v-on="on">
                          <country-flag
                            :country="item.options.language.slice(-2)"
                            size="small"
                          />
                        </v-avatar>
                      </template>
                      <span>{{
                        $t("gw.selectedLanguage", {
                          lang: $t(
                            `languages.${item.options.language}.display`
                          ),
                        })
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td class="py-4">
                    <div class="font-weight-bold secondary--text">
                      {{ item.name }}
                    </div>
                    <div class="caption secondary--text">
                      {{ getTimeDifference(item) }}
                    </div>
                  </td>
                  <td v-if="$vuetify.breakpoint.smAndUp">
                    <div>
                      <v-tooltip v-if="!item.disabled" left color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            label
                            color="grey lighten-2"
                            class="secondary--text mx-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon x-small class="mr-2"> mdi-school </v-icon>
                            <span>{{ item.nugget_count }}</span>
                          </v-chip>
                        </template>
                        <span>{{ $t("resources.nuggets.plural") }}</span>
                      </v-tooltip>
                      <v-tooltip v-if="!item.disabled" left color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            label
                            color="grey lighten-2"
                            v-bind="attrs"
                            class="secondary--text"
                            v-on="on"
                          >
                            <v-icon x-small class="mr-2">
                              mdi-comment-question
                            </v-icon>
                            <span>{{ item.question_count }}</span>
                          </v-chip>
                        </template>
                        <span>{{ $t("resources.questions.plural") }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwSessionBulk",
  components: {
    ViewLoader,
    CountryFlag,
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: this.$t("resources.users.status"),
          value: "status",
          width: "50px",
        },
        {
          text: this.$t("general.language"),
          value: "options.language",
          width: "50px",
        },
        {
          text: this.$t("resources.categories.name"),
          value: "name",
          width: "auto",
        },
        {
          text: this.$t("resources.nuggets.content"),
          value: "stats",
          width: "260px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { sessions: "get_bulk_sessions" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.modes.bulk"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
  },
  destroyed() {
    this.set_bulk_sessions([]);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_bulk_sessions"]),
    ...mapMutations("ghostwriter", ["set_bulk_sessions"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_bulk_sessions();
      this.loading = false;
    },
    getTimeDifference(item) {
      if (item.disabled) return this.$t("gw.invalid_session"); // no lang temp fix
      const now = new Date();
      const diffTime = Math.abs(now - item.created_at);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffHours <= 1) return this.$t("gw.createdJustNow");
      if (diffHours > 24) return this.$t("gw.xDaysAgo", { number: diffDays });
      return this.$t("gw.xHoursAgo", { number: diffHours });
    },
    goToDetails(item) {
      if (item.disabled) {
        // no lang temp fix
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.invalid_session_error"),
        });
        return false;
      }
      this.$router.push({
        name: "GwSessionBulkMain",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
#gw-sessions tr.hover:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  cursor: pointer;
}
tr.invalid {
  background: rgba(0, 0, 0, 0.05) !important;
} // no lang tmp fix
</style>
