<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <SectionHeader
        title="Ghostwriter Lizenzen *T"
        subtitle="Übersicht aller verfügbaren Lizenzen *T"
      />

      <v-container>
        <v-row>
          <v-col cols="12">
            <GwLicenceList :licences="licences" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!loading">
      <SectionHeader
        title="Credit-Pool *T"
        subtitle="Ein gemeinsames Konto, auf welches alle berechtigten Lizenzen zugreifen können *T"
      />

      <GwCreditPoolManager />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import GwCreditPoolManager from "@/components/administration/ghostwriter/GwCreditPoolManager";
import GwLicenceList from "@/components/ghostwriter/GwLicenceList";

export default {
  name: "GhostwriterLicenceAdministration",
  components: {
    ViewLoader,
    SectionHeader,
    GwCreditPoolManager,
    GwLicenceList,
  },
  data() {
    return {
      loading: true,
      open: [],
      licencePage: 1,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { licences: "get_licences" }),
  },
  destroyed() {
    this.set_licences([]);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_licences"]),
    ...mapMutations("ghostwriter", ["set_licences"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_licences();
      this.loading = false;
    },
  },
};
</script>

<style></style>
