<template>
  <draggable
    :list="items"
    class="list-group"
    v-bind="dragOptions"
    :empty-insert-threshold="500"
    @start="isDragging = true"
    @end="
      isDragging = false;
      changed();
    "
  >
    <transition-group type="transition" name="list">
      <div v-for="(item, index) in items" :key="item.id">
        <slot
          name="listelement"
          :item="item"
          :index="index"
          class="list-group-item"
        />
      </div>
    </transition-group>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SortableList",
  components: {
    draggable,
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "list",
        disabled: this.disabled,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    data: function (val) {
      this.items = [...val];
    },
  },
  mounted() {
    this.items = [...this.data];
  },
  methods: {
    changed() {
      this.$emit("changed", this.items);
    },
  },
};
</script>

<style>
.list-move {
  transition: transform 1s;
}
.no-move {
  transition: transform 1s;
}
.ghost {
  opacity: 0.5;
  background: grey;
}
.list-group {
  min-height: 20px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
