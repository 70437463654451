import api from "@/services/api";
import { Journey } from "@/models/journeys/Journey";
import { Journeybundle } from "@/models/journeys/Journeybundle";
import { Userjourney } from "@/models/journeys/Userjourney";
import { Certificate } from "@/models/Certificate";

export const fetch_journeys = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/journeys/", config);
    if (res._status === 200) {
      let journeys = res.journeys.map((journey) => {
        return new Journey(journey);
      });
      commit("set_journeys", journeys);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_journey = async (
  { commit },
  { id, language, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/journeys/${id}?language=${language}`,
      config
    );
    if (res._status === 200) {
      commit("set_journey", new Journey(res.journey));
      let bundles = res.journey.bundles.map((journey) => {
        return new Journeybundle(journey);
      });
      commit("set_journey_bundles", bundles);
      return res;
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_user_journey = async (
  { commit },
  { journey_id, user_id, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/journeys/${journey_id}/user/${user_id}`,
      config
    );
    if (res._status === 200) {
      commit("set_user_journey", new Userjourney(res.user_journey));
      return res;
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_journey_statistics = async (
  { commit },
  { id, config = {} }
) => {
  try {
    let res = await api.get(`admin/journeys/${id}/statistics`, config);
    if (res._status === 200) {
      commit("set_journey_statistics", res.users);
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_journey_nuggets = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let search = "";
    let category = "";
    let limit = 0;
    if (payload.search) search = `search=${payload.search}`;
    if (payload.category_id) category = `category_id=${payload.category_id}`;
    if (payload.search && payload.category_id) category = "&".concat(category);
    let res = await api.get(
      `admin/journeys/nuggets?limit=${limit}&is_active=1&${search}${category}&language=${payload.language}`,
      config
    );
    if (res._status === 200) {
      commit("set_journey_nuggets", res.nuggets);
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_journey_questions = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let search = "";
    let category = "";
    let limit = 0;
    if (payload.search) search = `search=${payload.search}`;
    if (payload.category_id) category = `category_id=${payload.category_id}`;
    if (payload.search && payload.category_id) category = "&".concat(category);
    let res = await api.get(
      `admin/journeys/questions?limit=${limit}&language=${payload.language}&isactive=1&${search}${category}`,
      config
    );
    if (res._status === 200) {
      commit("set_journey_questions", res.questions);
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_journey_quiz = async (_, { payload, config = {} }) => {
  try {
    let res = await api.get(
      `admin/journey_bundle_steps/${payload.id}/quiz`,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const add_journey = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/journeys/", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const patch_journey = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.patch(
      `admin/journeys/${payload.journey_id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: payload.language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const patch_journey_status = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/journeys/${payload.journey_id}/status`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const delete_journey = async (_, { id, config = {} }) => {
  try {
    let res = await api.delete(`admin/journeys/${id}`, config);
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const add_journey_substructures = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/journeys/${payload.journey_id}/substructures`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const delete_journey_substructure = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(
      `admin/journeys/${payload.journey_id}/substructures/${payload.substructure_id}`,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const fetch_bundle = ({ state, commit }, { id }) => {
  var bundle = state.journeyBundles.filter((b) => b.id === parseInt(id))[0];
  if (bundle) {
    commit("set_bundle", bundle);
  } else {
    console.error("BUNDLE NOT FOUND!");
  }
};

export const add_bundle = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/journeys/${payload.journey_id}/bundles`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const patch_bundle = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/journey_bundles/${payload.bundle_id}`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
      dispatch("fetch_bundle", {
        id: payload.bundle_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const add_bundle_steps = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/journey_bundles/${payload.bundle_id}/steps`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
      await dispatch("fetch_bundle", {
        id: payload.bundle_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const patch_bundle_step = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/journey_bundle_steps/${payload.step_id}/quiz`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_bundle", {
        id: payload.bundle_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const move_bundles = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("admin/journey_bundles/move", payload, config);
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const move_bundle_steps = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.post(
      "admin/journey_bundle_steps/move",
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_bundle", {
        id: payload.bundle_id,
        language: state.selected_language,
      });
    }
    return res;
  } catch (err) {
    return null;
  }
};

export const delete_bundle = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(
      `admin/journey_bundles/${payload.bundle_id}`,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const delete_bundle_step = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(
      `admin/journey_bundles/${payload.bundle_id}/steps/${payload.step_id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_bundle", {
        id: payload.bundle_id,
        language: state.selected_language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const duplicate_journey = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post(
      `admin/journeys/${payload.journey_id}/duplicate`,
      payload,
      config
    );
    return res;
  } catch (err) {
    return null;
  }
};

export const export_journey_stats = async (_, { payload, config = {} }) => {
  try {
    let res = await api.get(
      `admin/journeys/${payload.journey_id}/export?format=${payload.format}`,
      config
    );
    return res;
  } catch (err) {
    return null;
  }
};

export const fetch_certificates = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/journeys/certificates", config);
    if (res._status === 200) {
      let certificates = res.certificates.map((certificate) => {
        return new Certificate(certificate);
      });
      commit("set_certificates", certificates);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_certificate = async (
  { commit },
  { id, language, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/journeys/certificates/${id}?language=${language}`,
      config
    );
    if (res._status === 200) {
      commit("set_certificate", new Certificate(res.certificate));
      return res;
    } else {
      return res;
    }
  } catch (err) {
    return null;
  }
};

export const update_certificate = async (
  { dispatch },
  { certificate, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/journeys/certificates/${certificate.id}`,
      certificate,
      config
    );
    if (res._status === 200) {
      //commit('set_certificate', new Certificate(res.certificate));
      await dispatch("fetch_certificate", {
        id: certificate.id,
        language: certificate.language,
      });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const add_certificate = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/journeys/certificates", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const update_certificate_default = async (
  { dispatch },
  { id, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/journeys/certificates/${id}/default`,
      null,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_certificates"); // TODO: lang?
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const preview_certificate = async (_, { id, language, config = {} }) => {
  try {
    let res = await api.get(
      `admin/journeys/certificates/${id}/preview?language=${language}`,
      config
    );
    if (res._status === 200) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_journey_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/journeys/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_journey_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/journeys/${payload.id}/translations`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey_translations", {
        payload: { id: payload.id },
      });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_journey_bundle_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/journey_bundles/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_journey_bundle_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_bundle_translation = async (
  { dispatch, state },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/journey_bundles/${payload.id}/translations`,
      payload,
      config
    );
    if (res._status === 200) {
      await dispatch("fetch_journey", {
        id: payload.journey_id,
        language: state.selected_language,
      });
      await dispatch("fetch_journey_bundle_translations", {
        payload: { id: payload.id },
      });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const fetch_certificate_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/journeys/certificates/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_certificate_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_certificate_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/journeys/certificates/${payload.id}/translations`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_certificate_translations", {
        payload: { id: payload.id },
      });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};
