<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="
        journey ? journey.name : $t('resources.journeys.journey.singular')
      "
      icon="mdi-comment-question"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          color="grey lighten-2"
          :to="{ name: 'JourneyCurrent' }"
          exact
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="!journey" />

    <section v-if="journey">
      <v-container>
        <v-row>
          <v-col cols="12" md="4" lg="3" class="pa-0 d-flex">
            <JourneyNav class="flex-grow-1" />

            <v-divider
              v-if="$vuetify.breakpoint.mdAndUp"
              vertical
              class="ml-2"
            />
          </v-col>
          <v-col cols="12" md="8" lg="9">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import JourneyNav from "@/components/journeys/JourneyNav.vue";

export default {
  name: "JourneyDetail",
  components: {
    ViewHeader,
    ViewLoader,
    JourneyNav,
  },
  data() {
    return {
      loading: true,
      id: 0,
      tab: null,
      showDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.journeys.title"),
          disabled: false,
          to: { name: "JourneyCurrent" },
        },
      ];
    },
  },
  watch: {
    language: {
      handler: function () {
        this.fetchData();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
    this.id = Number(this.$route.params.id);
  },
  destroyed() {
    this.set_journey(null);
    this.set_structures(null);
    this.set_substructures(null);
  },
  methods: {
    ...mapActions("journeys", ["fetch_journey"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("journeys", ["set_journey"]),
    ...mapMutations("structures", ["set_structures", "set_substructures"]),
    async fetchData() {
      this.loading = true;
      if (this.id) {
        let res = await this.fetch_journey({
          id: this.id,
          language: this.language,
        });
        if (res && res._status === 403) this.$router.push("/error/403");
        await this.fetch_structures();
      }
      this.loading = false;
    },
    closeDeleteDialog(wasDeleted) {
      this.showDeleteDialog = false;
      if (wasDeleted) this.$router.push({ name: "JourneyCurrent" });
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
  },
};
</script>

<style></style>
