var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('ViewLoader'):_vm._e(),(!_vm.loading)?_c('section',[_c('v-container',[(!_vm.licences.ghostwriter || _vm.licenceExpired)?_c('v-alert',{attrs:{"text":"","type":"error","dense":"","border":"left"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("gw.no_licence")))])]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"grey lighten-3",attrs:{"id":"gw-sessions","headers":_vm.table_headers,"items":_vm.visibleTranscriptions,"items-per-page":10},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{staticClass:"hover",class:{ invalid: item.disabled },on:{"click":function($event){return _vm.goToDetails(item)}}},[_c('td',[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[(![1, 2, 6].includes(item.status))?_c('v-icon',{attrs:{"color":item.status_color,"small":""}},[_vm._v(" "+_vm._s(item.status_icon)+" ")]):_vm._e(),([1, 2, 6].includes(item.status))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"16","width":"2"}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.disabled ? _vm.$t("gw.invalid_session") : item.status === 3 ? _vm.$t("gw.transcriptions.status_pending") : _vm.$t(`gw.status.${item.status}`)))])])],1),_c('td',[(
                      !item.language ||
                      (item.language && item.language.length === 2)
                    )?_c('span',{staticClass:"caption"},[_vm._v("LANG ERR ("+_vm._s(item.language)+")")]):_vm._e(),(
                      !item.disabled &&
                      item.language &&
                      item.language.length > 2
                    )?_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-avatar',_vm._g(_vm._b({attrs:{"tile":"","size":"24"}},'v-avatar',attrs,false),on),[_c('country-flag',{attrs:{"country":item.language.length === 2
                              ? item.language
                              : item.language.slice(-2),"size":"small"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("gw.selectedLanguage", { lang: _vm.$t(`languages.${item.language}.display`), })))])]):_vm._e()],1),_c('td',{staticClass:"py-4"},[_c('div',{staticClass:"font-weight-bold secondary--text"},[_vm._v(" "+_vm._s(item.file_name)+" ")]),_c('div',{staticClass:"caption secondary--text"},[_vm._v(" "+_vm._s(item.description ? item.description : "-")+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"left":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":item.type === 'youtube' ? 'error' : 'primary',"small":""}},[_vm._v(" "+_vm._s(item.type === "youtube" ? "mdi-youtube" : "mdi-upload")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type === "youtube" ? _vm.$t("gw.transcriptions.types.youtube") : _vm.$t("gw.transcriptions.fileupload")))])])],1),_c('td',[_c('span',{staticClass:"caption secondary--text"},[_vm._v(_vm._s(item.created_at.toLocaleDateString()))])])])]}}],null,false,1727679300)})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }