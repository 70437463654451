<template>
  <div>
    <v-container v-if="$vuetify.breakpoint.smAndDown" class="ml-3">
      <v-btn small color="primary" @click="toggleMenu">
        {{
          !showMenu
            ? $t("resources.journeys.showMenu")
            : $t("resources.journeys.hideMenu")
        }}
      </v-btn>
    </v-container>
    <v-container
      v-if="$vuetify.breakpoint.mdAndUp || showMenu"
      class="flex-grow-1"
    >
      <v-list dense nav class="transparent">
        <!-- v-if="!item.navItems" -->
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          :to="item.link"
          color="secondary"
          class="my-0 journey-nav-item"
          active-class="journey-nav-item-active"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        v-if="
          ($route.name == 'JourneyBundles' || $route.name == 'JourneyBundle') &&
          journey.bundles.length > 0
        "
        dense
        nav
        class="transparent pl-6"
        max-width="90%"
      >
        <v-list-item
          v-for="bundle in sortedBundles"
          :key="bundle.id"
          :to="{ name: 'JourneyBundle', params: { bundle_id: bundle.id } }"
          class="lighten-2 py-0 my-0 journey-nav-item"
          color="secondary"
          active-class="journey-nav-item-active"
        >
          <v-list-item-content>
            <span class="caption">{{ bundle.name }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JourneyNav",
  components: {},
  data() {
    return {
      navItems: [
        {
          title: this.$t("resources.journeys.navItems.overview"),
          link: { name: "JourneySummary" },
        },
        {
          title: this.$t("resources.journeys.navItems.settings"),
          link: { name: "JourneySettings" },
        },
        {
          title: this.$t("resources.journeys.navItems.visibility"),
          link: { name: "JourneyStructures" },
        },
        {
          title: this.$t("certificate.singular"),
          link: { name: "JourneyCertificate" },
        },
        {
          title: this.$t("resources.journeys.navItems.evaluation"),
          link: { name: "JourneyStatistics" },
        },
        {
          title: this.$t("resources.journeys.navItems.contents"),
          link: { name: "JourneyBundles" },
        },
      ],
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      bundles: "get_journey_bundles",
    }),
    sortedBundles() {
      if (!this.bundles) return [];
      let bundles = [...this.bundles].sort((a, b) => a.level - b.level);
      return bundles;
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>

<style>
.journey-nav-item {
  border-left: 4px solid #617d8b;
  border-radius: 0 !important;
  background: transparent !important;
}
.journey-nav-item-active {
  border-left: 4px solid #00afd7;
  background-color: rgba(0, 0, 0, 0) !important;
}
.journey-nav-item-active .v-list-item__content {
  color: #00afd7 !important;
}
.journey-nav-item.theme--light.v-list-item--active:before,
.journey-nav-item.theme--light.v-list-item--active:hover:before,
.journey-nav-item.theme--light.v-list-item:focus:before {
  opacity: 0;
}
</style>
