<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('gw.sessionImport.title')"
      icon="mdi-typewriter"
      :margin-bottom="showTutorial ? 0 : 5"
    >
      <template #titleButtonLeft>
        <v-btn
          v-if="!showTutorial"
          icon
          small
          class="ml-2"
          color="secondary"
          @click="openTutorial()"
        >
          <v-icon small> mdi-help-circle </v-icon>
        </v-btn>
      </template>
      <template #titleButton>
        <div class="d-flex align-center">
          <GwCreditInfoMenu />
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'GwSessionList' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading" />

    <v-expand-transition>
      <GwImportTutorial
        v-show="showTutorial && !loading"
        class="mb-4"
        @close="showTutorial = false"
      />
    </v-expand-transition>

    <section v-if="!loading">
      <SectionHeader
        :title="session.name"
        :subtitle="$t('resources.questions.sectionHeaders.overview.title')"
      >
        <template #action>
          <div>
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  class="mr-2"
                  :color="showOptions ? 'primary' : 'secondary'"
                  v-bind="attrs"
                  v-on="on"
                  @click="showOptions = !showOptions"
                >
                  <v-icon small> mdi-cogs </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("resources.invitations.settings") }}</span>
            </v-tooltip>
            <v-btn
              small
              depressed
              color="secondary"
              :disabled="session.status !== 0"
              :to="{ name: 'GwSessionDetail', params: { id: id } }"
            >
              <v-icon small class="mr-2"> mdi-chevron-left </v-icon>
              <span>{{ $t("gw.sessionImport.toSession") }}</span>
            </v-btn>
          </div>
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col cols="12" sm="4">
            <StatisticsCardSmall
              :title="$t(`gw.status.${session.status}`)"
              :subtitle="$t('resources.support.props.status')"
              :icon="session.status_icon"
              :color="session.status_color"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StatisticsCardSmall
              :title="`${session.nugget_count_accepted} / ${session.nugget_count}`"
              :subtitle="$t('gw.acceptedNuggets')"
              icon="mdi-school"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StatisticsCardSmall
              :title="`${session.question_count_accepted} / ${session.question_count}`"
              :subtitle="
                $t('resources.users.statistics_list.acceptedQuestionsSub')
              "
              icon="mdi-comment-question"
              color="grey lighten-3"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!loading" class="mt-6">
      <SectionHeader
        :title="$t('gw.sessionImport.questionsAndNuggets')"
        :subtitle="$t('gw.sessionImport.contentOverview')"
      >
        <template #action>
          <v-btn
            depressed
            color="primary"
            small
            :disabled="
              session.status !== 0 ||
              (session.question_count_accepted === 0 &&
                session.nugget_count_accepted === 0) ||
              !session.whole_content ||
              session.whole_content.length === 0 ||
              !session.defaults.category_id
            "
            @click="importContent()"
          >
            <v-icon small class="mr-2"> mdi-import </v-icon>
            <span>{{ $t("gw.sessionDetail.importContent") }}</span>
          </v-btn>
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col>
            <v-card
              v-if="session.status === 2"
              type="info"
              color="secondary"
              dark
              flat
            >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" sm="6" md="6" offset-md="1">
                    <h5>{{ $t("gw.patience_please") }}</h5>
                    <p>
                      {{ $t("gw.bulk.hints.generating_time") }}
                    </p>
                    <div class="d-flex align-center">
                      <v-progress-linear
                        color="white"
                        indeterminate
                        height="8"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="text-center">
                    <v-avatar size="180" color="white">
                      <v-img src="@/assets/img/misc/ghostwriter.png" />
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-alert
              v-if="!licences.ghostwriter || licenceExpired"
              text
              type="error"
              dense
              border="left"
            >
              <span class="caption">{{ $t("gw.no_licence") }}</span>
            </v-alert>
            <v-alert
              v-if="!session.defaults.category_id"
              type="error"
              border="left"
              text
              dense
            >
              <span class="caption">{{ $t("gw.categoryNeeded") }}</span>
            </v-alert>
            <v-alert
              v-if="
                session.question_count_accepted < session.question_count ||
                session.nugget_count_accepted < session.nugget_count
              "
              color="secondary"
              type="info"
              border="left"
              text
              dense
            >
              <span class="caption">{{
                $t("gw.sessionImport.acceptHint")
              }}</span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            v-for="(obj, i) in session.whole_content"
            :key="i"
            cols="12"
            sm="6"
          >
            <GwContentListItem
              :item="obj"
              :session-id="session.id"
              class="mb-2"
              :elevation="obj.is_accepted ? 0 : 0"
              :color="obj.is_accepted ? 'grey lighten-3' : 'grey lighten-2'"
              @click="openContent(i)"
            />
          </v-col>
          <v-col>
            <v-list-item
              v-if="
                !session.whole_content || session.whole_content.length === 0
              "
              class="grey lighten-3"
              dense
            >
              <v-list-item-content class="caption">
                {{ $t("gw.sessionImport.sessionWithoutContent") }}
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            v-if="session.whole_content.length > 10"
            cols="12"
            class="text-right"
          >
            <v-btn
              depressed
              color="primary"
              small
              :disabled="
                session.status !== 0 ||
                (session.question_count_accepted === 0 &&
                  session.nugget_count_accepted === 0) ||
                !session.whole_content ||
                session.whole_content.length === 0
              "
              @click="importContent()"
            >
              <v-icon small class="mr-2"> mdi-import </v-icon>
              <span>{{ $t("gw.sessionDetail.importContent") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <GwContentDialog
      v-if="!loading"
      :content="session.whole_content"
      :session-id="session.id"
      :index="contentIndex"
      :visible="showContent"
      :disabled="
        !licences.ghostwriter || licenceExpired || session.status !== 0
      "
      @close="showContent = false"
    />

    <GwSessionOptions
      v-if="!loading"
      short-version
      :session="session"
      :visible="showOptions"
      :disabled="session.status !== 0"
      :categories="categories"
      @close="showOptions = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader.vue";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import GwContentListItem from "@/components/ghostwriter/GwContentListItem";
import GwContentDialog from "@/components/ghostwriter/GwContentDialog";
import GwSessionOptions from "@/components/ghostwriter/GwSessionOptions";
import GwImportTutorial from "@/components/ghostwriter/GwImportTutorial";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";

export default {
  name: "GwSessionImport",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    StatisticsCardSmall,
    GwContentListItem,
    GwContentDialog,
    GwSessionOptions,
    GwImportTutorial,
    GwCreditInfoMenu,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      showContent: false,
      contentIndex: 0,
      showOptions: false,
      showTutorial: false,
      progress: null,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.modes.single"),
          disabled: false,
          to: { name: "GwSessionList" },
        },
        {
          text: this.$t("general.details"),
          disabled: false,
          to: { name: "GwSessionDetail", params: { id: this.id } },
        },
        {
          text: this.$t("gw.import"),
          disabled: true,
        },
      ];
    },
  },
  session: {
    handler: function (v) {
      if (v) this.checkStatus();
    },
    deep: true,
  },
  beforeMount() {
    if (
      !localStorage.getItem("gw_disable_tut_import") ||
      localStorage.getItem("gw_disable_tut_import") !== "true"
    ) {
      this.showTutorial = true;
    }
  },
  /* mounted() {
    this.checkStatus();
  }, */
  destroyed() {
    if (this.progress) clearInterval(this.progress);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_session", "import_session"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("ghostwriter", ["set_session"]),
    async fetchData() {
      if (!Number(this.id)) return false;
      this.loading = true;
      await this.fetch_session({ id: this.id });
      if (this.session.status === 0) this.showOptions = true;
      await this.fetch_categories();

      this.checkStatus();
      this.loading = false;
    },
    openContent(idx) {
      this.contentIndex = idx;
      this.showContent = true;
    },
    openTutorial() {
      if (localStorage.getItem("gw_disable_tut_import"))
        localStorage.removeItem("gw_disable_tut_import");
      this.showTutorial = true;
    },
    async importContent() {
      this.loading = true;
      this.showOptions = false;
      let payload = { id: this.session.id };
      let res = await this.import_session({
        payload: payload,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.checkStatus();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async refreshSession() {
      if (![2].includes(this.session.status)) {
        this.progress = clearInterval(this.progress);
        return false;
      }
      await this.fetch_session({
        id: this.session.id,
      });
    },
    async checkStatus() {
      if (!this.session) return false;
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (!!this.progress || ![2].includes(this.session.status)) return false;
      this.progress = setInterval(
        function () {
          this.refreshSession();
        }.bind(this),
        6000
      );
    },
  },
};
</script>
