<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editBundles.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.editBundles.subtitle')"
      />
      <v-container v-if="bundles">
        <v-card elevation="0" color="grey lighten-3">
          <v-btn
            v-if="
              ['unpublished'].includes(journey.status) &&
              ['superadmin', 'admin', 'editor'].includes(role)
            "
            fab
            :color="addBundleMode ? 'error' : 'success'"
            top
            right
            x-small
            absolute
            :disabled="loading"
            @click="toggleAddBundleMode"
          >
            <v-icon>{{ addBundleMode ? "mdi-close" : "mdi-plus" }}</v-icon>
          </v-btn>
          <v-card-text>
            <v-form ref="addBundleForm" v-model="valid" :disabled="loading">
              <v-row v-if="addBundleMode">
                <v-col cols="6">
                  <LanguageChooserInline
                    :label="$t('ng.source_language')"
                    background-color="white"
                    colored
                    :initial="input.language"
                    @change="changeLanguage"
                  />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="input.level"
                    :items="bundleLevels"
                    dense
                    outlined
                    background-color="white"
                    :label="$t('resources.journeys.level')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="input.name"
                    :counter="100"
                    :rules="rules.name"
                    required
                    dense
                    background-color="white"
                    outlined
                    :label="$t('resources.journeys.propNames.name')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="input.description"
                    :counter="200"
                    :rules="rules.description"
                    required
                    dense
                    outlined
                    background-color="white"
                    :label="$t('resources.journeys.propNames.description')"
                  />
                </v-col>
                <v-col cols="12">
                  <ImageUpload
                    :image-hash="input.image_token"
                    :disabled="
                      !['superadmin', 'admin', 'editor'].includes(role) ||
                      loading
                    "
                    card-color="grey lighten-2"
                    @image-changed="imageChange"
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    color="error"
                    class="mr-4"
                    small
                    depressed
                    :disabled="
                      (!input.name && !input.level && input.description) ||
                      loading
                    "
                    @click="reset"
                  >
                    {{ $t("general.clear") }}
                  </v-btn>

                  <v-btn
                    color="success"
                    :disabled="!valid"
                    small
                    :loading="loading"
                    depressed
                    @click="addBundle"
                  >
                    {{ $t("general.add") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-divider v-if="addBundleMode" class="my-2" />

            <p
              class="body-2 secondary--text"
              v-html="$t('resources.journeys.levelHint')"
            />

            <BundleSorter :journey="journey" :bundles="bundles" />
          </v-card-text>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import BundleSorter from "@/components/journeys/BundleSorter";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import ImageUpload from "@/components/global/ImageUpload";

export default {
  name: "JourneyBundles",
  components: {
    SectionHeader,
    BundleSorter,
    LanguageChooserInline,
    ImageUpload,
  },
  data() {
    return {
      id: 0,
      levels: [],
      addBundleMode: false,
      input: {
        name: "",
        description: "",
        image: "",
        image_token: "",
        level: 0,
        language: this.$i18n.locale,
      },
      valid: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
            }),
          (v) =>
            (v && v.length <= 100) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
              max: 100,
            }),
        ],
        description: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
            }),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
              max: 200,
            }),
        ],
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      bundles: "get_journey_bundles",
      language: "get_selected_language",
    }),
    bundleLevels() {
      let levels = [1];
      if (this.bundles && this.bundles.length > 0) {
        this.bundles.forEach((bundle) => {
          if (!levels.includes(bundle.level)) levels.push(bundle.level);
        });
        levels.push(levels[levels.length - 1] + 1);
        return levels.sort();
      }
      return levels;
    },
  },
  methods: {
    ...mapActions("journeys", ["add_bundle"]),
    ...mapMutations("journeys", ["set_journey_bundles"]),
    toggleAddBundleMode() {
      this.addBundleMode = !this.addBundleMode;
      if (!this.addBundleMode) this.reset();
    },
    reset() {
      this.input.image_token = null;
      this.input.image = null;
      this.$refs.addBundleForm.reset();
    },
    async addBundle() {
      if (!this.valid) return false;
      this.loading = true;

      let input = { ...this.input };
      let payload = {
        journey_id: this.journey.id,
        name: input.name,
        description: input.description,
        level: input.level ? input.level : 0,
        image: input.image,
        language: input.language,
      };

      let res = await this.add_bundle({
        payload: payload,
      });
      this.loading = false;

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.toggleAddBundleMode();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("general.createError"),
        });
      }
    },
    changeLanguage(val) {
      this.input.language = val;
    },
    imageChange(image) {
      this.input.image_token = image.hash;
      this.input.image = image.filename;
    },
  },
};
</script>

<style></style>
