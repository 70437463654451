<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.statsContent.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.statsContent.subtitle')
        "
      />

      <JourneyStatsContent />
    </section>

    <section class="mt-4">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.statsActivity.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.statsActivity.subtitle')
        "
      />

      <JourneyStatsActivity />
    </section>

    <section
      v-if="!hideUserStats && ['superadmin', 'admin', 'manager'].includes(role)"
      class="mt-4"
    >
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.statsUser.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.statsUser.subtitle')"
      />
      <JourneyStatsUser />
    </section>

    <section
      v-if="['superadmin', 'admin', 'manager'].includes(role)"
      class="mt-4"
    >
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editActions.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.editActions.subtitle')"
      />
      <v-container>
        <v-row>
          <v-col cols="12">
            <ExportDataDialog type="journey" :journey-id="journey.id" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneyStatsContent from "@/components/journeys/stats/JourneyStatsContent";
import JourneyStatsActivity from "@/components/journeys/stats/JourneyStatsActivity";
import JourneyStatsUser from "@/components/journeys/stats/JourneyStatsUser";
import ExportDataDialog from "@/components/global/ExportDataDialog";

export default {
  name: "JourneyStatistics",
  components: {
    SectionHeader,
    JourneyStatsContent,
    JourneyStatsActivity,
    JourneyStatsUser,
    ExportDataDialog,
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      stats: "get_journey_statistics",
    }),
    hideUserStats() {
      if (process.env.VUE_APP_HIDE_JOURNEY_USER_STATS === "true") return true;
      return false;
    },
  },
  destroyed() {
    this.set_journey_statistics(null);
  },
  methods: {
    ...mapActions("journeys", ["fetch_journey_statistics"]),
    ...mapMutations("journeys", ["set_journey_statistics"]),
    async fetchData() {
      if (["superadmin", "admin", "manager"].includes(this.role))
        await this.fetch_journey_statistics({
          id: this.journey.id,
        });
    },
  },
};
</script>

<style></style>
