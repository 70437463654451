var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("action",null,{"openDialog":_vm.openDialog}),_c('v-dialog',{attrs:{"max-width":"640","scrollable":""},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.showDialog)?_c('v-card',{attrs:{"color":"grey lighten-3","max-width":"640"}},[_c('v-card-title',{staticClass:"overline secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("ng.user_journey"))+" ")]),(!_vm.user_journey)?_c('v-card-text',{staticClass:"px-2"},[_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.$t("general.data_is_loading")))])]):_vm._e(),_c('v-expand-transition',[(_vm.user_journey)?_c('v-card-text',[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(`${_vm.user.first_name} ${_vm.user.last_name}`))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.user_name))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"outlined":"","color":_vm.user_journey.status === 'finished'
                    ? 'success'
                    : _vm.user_journey.status === 'failed'
                    ? 'error'
                    : '',"dark":['finished', 'failed'].includes(_vm.user_journey.status)}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user_journey.status ? _vm.$t( `resources.journeys.user_statuses.${_vm.user_journey.status}` ) : _vm.$t("resources.journeys.user_statuses.not_started")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("resources.journeys.status")))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.journey.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("resources.journeys.journey.singular")))])],1),(_vm.journey.journey_id)?_c('v-list-item-action',[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"secondary","to":{
                        name: 'JourneySummary',
                        params: { id: _vm.journey.journey_id },
                      },"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-arrow-top-right-thin")])],1)],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user_journey.quiz_results === "-" ? "-" : `${_vm.user_journey.quiz_results.toFixed(2)}%`)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t("ng.current_quiz_score")))])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user_journey.attempts_left))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("ng.remaining_attempts"))+" ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.formatDate(_vm.user_journey.created_at)))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("resources.support.props.created_at"))+" ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.formatDate(_vm.user_journey.updated_at)))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("general.updated_at"))+" ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("resources.journeys.bundle.plural"))+" ")]),(
                  !_vm.user_journey.bundles || _vm.user_journey.bundles.length === 0
                )?_c('p',[_vm._v(" "+_vm._s(_vm.$t("general.no_content"))+" ")]):_vm._e(),_c('v-expansion-panels',{attrs:{"flat":"","multiple":""}},_vm._l((_vm.sortedBundles),function(bundle,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{staticClass:"py-0"},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',[_c('v-avatar',{attrs:{"size":"24","color":"grey lighten-3 mt-2"}},[_vm._v(" "+_vm._s(bundle.level)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(bundle.name))])],1),_c('v-list-item-avatar',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":bundle.status_color}},[_vm._v(" "+_vm._s(bundle.status_icon)+" ")])],1)],1)],1),_c('v-expansion-panel-content',[_c('v-list',{attrs:{"dense":""}},_vm._l((bundle.steps),function(step,n){return _c('v-list-item',{key:`step-${n}`,style:(n > 0 ? 'border-top: 1px solid rgba(0,0,0,.12)' : '')},[_c('v-list-item-icon',{staticClass:"pt-2"},[_c('v-avatar',{attrs:{"size":"24","color":"grey lighten-3"}},[_vm._v(" "+_vm._s(step.origin.order)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(step.type === "nugget" ? step.nugget.title : `${step.question_count} ${_vm.$t( "resources.questions.plural" )}`))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(step.type === "nugget" ? _vm.$t("resources.nuggets.singular") : _vm.$t( "resources.journeys.stepTypes.quiz.singular" )))])],1),_c('v-list-item-content',{staticClass:"justify-end caption"},[_vm._v(_vm._s(step.status === "locked" ? "-" : step.status === "unlocked" ? _vm.$t( "resources.journeys.user_statuses.in_progress" ) : _vm.$t( `resources.journeys.user_statuses.${step.status}` )))])],1)}),1)],1),_c('v-divider')],1)}),1)],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"secondary","loading":!_vm.user_journey},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1)],1):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }