<template>
  <v-dialog :value="showDialog" width="500" @click:outside="closeDialog()">
    <v-card :loading="isLoading">
      <v-card-title class="text-overline pb-0">
        <span>{{ $t("resources.journeys.changeStatus") }}</span>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="5" class="text-center">
              <v-card elevation="0" color="grey lighten-3" class="py-2">
                <h5 class="secondary--text">
                  {{ $t("resources.journeys.old_status") }}
                </h5>
                <p class="ma-0">
                  {{ $t(`resources.journeys.statuses.${journey.status}`) }}
                </p>
              </v-card>
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-center">
              <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col cols="5" class="text-center">
              <v-card elevation="0" color="grey lighten-3" class="py-2">
                <h5 class="secondary--text">
                  {{ $t("resources.journeys.new_status") }}
                </h5>
                <p class="ma-0">
                  {{ $t(`resources.journeys.statuses.${newStatus}`) }}
                </p>
              </v-card>
            </v-col>
            <v-col cols="12">
              <small
                v-html="
                  $t(
                    `resources.journeys.statusChanger.${journey.status}_${newStatus}`
                  )
                "
              />
            </v-col>
          </v-row>

          <v-row v-if="errors.length > 0">
            <v-col cols="12">
              <v-alert
                v-for="(error, i) in errors"
                :key="i"
                dense
                text
                color="error"
                border="left"
              >
                <span class="body-2">{{ $t(`errors.${error}`) }}</span>
              </v-alert>
            </v-col>
          </v-row>

          <v-checkbox
            v-if="errors.length == 0"
            v-model="confirmed"
            color="primary"
            :label="$t('general.confirm')"
            class="mt-0 overline"
          />
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text small @click="closeDialog()">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn
          color="success"
          small
          text
          :disabled="!confirmed || errors.length > 0"
          @click="changeStatus()"
        >
          {{ $t("resources.journeys.changeStatus") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { Journey } from "@/models/journeys/Journey.js";

export default {
  name: "JourneyDialogDelete",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    journey: {
      type: Journey,
      required: true,
    },
    newStatus: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      confirmed: false,
      errors: [],
    };
  },
  computed: {
    showDialog() {
      return this.dialog;
    },
  },
  methods: {
    ...mapActions("journeys", ["patch_journey_status"]),
    closeDialog(pleaseDelete) {
      this.$emit("close", pleaseDelete);
    },
    async changeStatus() {
      let payload = {
        journey_id: this.journey.id,
        status: this.newStatus,
      };
      let res = await this.patch_journey_status({
        payload: payload,
      });
      if (res.success) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.closeDialog(true);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
        this.errors = res.errors;
        this.confirmed = false;
      }
    },
  },
};
</script>

<style></style>
