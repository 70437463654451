<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editStructures.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.editStructures.subtitle')
        "
      />

      <v-container v-if="journey && substructures">
        <v-card elevation="0" color="grey lighten-3">
          <v-card-text>
            <v-autocomplete
              v-model="newSubstructures"
              :items="availableSubstructures"
              item-text="name"
              item-value="id"
              multiple
              :placeholder="$t('resources.substructures.plural')"
              :disabled="
                ['closed', 'expired'].includes(journey.status) ||
                !['superadmin', 'admin', 'editor'].includes(role)
              "
            >
              <template #item="{ item }">
                <v-checkbox :value="isSelected(item)" />
                <div>
                  {{ getSubstructureById(item.id).structure.name }} /
                  {{ getSubstructureById(item.id).name }}
                </div>
              </template>
              <template #append-outer>
                <v-btn
                  fab
                  x-small
                  color="success"
                  :disabled="
                    !newSubstructures ||
                    newSubstructures.length === 0 ||
                    ['closed', 'expired'].includes(journey.status) ||
                    !['superadmin', 'admin', 'editor'].includes(role)
                  "
                  @click="add()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <template #selection="{ item }">
                <v-chip small color="grey lighten-2">
                  {{ getSubstructureById(item.id).structure.name }} /
                  {{ getSubstructureById(item.id).name }}
                </v-chip>
              </template>
            </v-autocomplete>

            <small v-html="$t('resources.journeys.structure_warning')" />

            <div v-if="journey.substructures.length > 0" class="mt-4">
              <p>{{ $t("resources.journeys.addedSubstructures") }}</p>
              <v-chip
                v-for="substructure in journey.substructures"
                :key="substructure"
                class="mr-2"
                color="primary"
              >
                <span
                  >{{ getSubstructureById(substructure).structure.name }} /
                  {{ getSubstructureById(substructure).name }}</span
                >
                <v-btn
                  icon
                  x-small
                  :disabled="
                    !['unpublished'].includes(journey.status) ||
                    !['superadmin', 'admin', 'editor'].includes(role)
                  "
                  class="ml-1"
                  @click="removeSubstructure(substructure)"
                >
                  <v-icon small color="white"> mdi-close-circle </v-icon>
                </v-btn>
              </v-chip>
            </div>

            <div v-else class="body-2 mt-4">
              {{ $t("resources.journeys.noStructuresYet") }}
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <JourneyDialogStructureWarning
      v-if="showWarningDialog"
      :dialog="showWarningDialog"
      @close="closeWarningDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneyDialogStructureWarning from "@/components/journeys/dialogs/JourneyDialogStructureWarning";

export default {
  name: "JourneyStructures",
  components: {
    SectionHeader,
    JourneyDialogStructureWarning,
  },
  data() {
    return {
      newSubstructures: [],
      showWarningDialog: false,
    };
  },
  computed: {
    ...mapGetters("structures", { substructures: "get_substructures" }),
    ...mapGetters("journeys", { journey: "get_journey" }),
    availableSubstructures() {
      return this.substructures.filter(
        (substructure) => !this.journey.substructures.includes(substructure.id)
      );
    },
  },
  methods: {
    ...mapActions("journeys", [
      "add_journey_substructures",
      "delete_journey_substructure",
    ]),
    getSubstructureById(id) {
      return this.substructures.filter(
        (substructure) => substructure.id === id
      )[0];
    },
    openWarningDialog() {
      this.showWarningDialog = true;
    },
    closeWarningDialog(confirm) {
      if (confirm) this.addSubstructure();
      this.showWarningDialog = false;
    },
    add() {
      if (["active", "paused"].includes(this.journey.status)) {
        this.openWarningDialog();
      } else {
        this.addSubstructure();
      }
    },
    async addSubstructure() {
      let payload = {
        journey_id: this.journey.id,
        substructures: [...this.newSubstructures],
      };
      let res = await this.add_journey_substructures({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.newSubstructures = [];
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async removeSubstructure(id) {
      let payload = {
        journey_id: this.journey.id,
        substructure_id: id,
      };
      let res = await this.delete_journey_substructure({
        payload: payload,
      });

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.$emit("update");
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    isSelected(substructure) {
      return (
        this.newSubstructures.filter((s) => s === substructure.id).length > 0
      );
    },
  },
};
</script>

<style>
.structure-list-item {
  border-left: 4px solid #617d8b !important;
  background: white;
}
</style>
