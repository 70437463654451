<template>
  <div>
    <section class="mt-4">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editCertificate.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.editCertificate.subtitle')
        "
      />

      <JourneyCertificateForm />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneyCertificateForm from "@/components/journeys/forms/JourneyCertificateForm";

export default {
  name: "JourneyCertificate",
  components: {
    SectionHeader,
    JourneyCertificateForm,
  },
  data() {
    return {
      loading: false,
      showDeleteDialog: false,
    };
  },
  computed: {
    ...mapGetters("journeys", { journey: "get_journey" }),
  },
  methods: {},
};
</script>

<style></style>
