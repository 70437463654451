<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('gw.ghostwriter')"
      icon="mdi-typewriter"
      :margin-bottom="showTutorial ? 0 : 5"
    >
      <template #titleButtonLeft>
        <v-btn
          v-show="['GwSessionList', 'GwSessionBulk'].includes($route.name)"
          :disabled="showTutorial"
          icon
          small
          class="ml-2"
          color="secondary"
          @click="openTutorial()"
        >
          <v-icon small> mdi-help-circle </v-icon>
        </v-btn>
      </template>

      <template #titleButton>
        <GwCreditInfoMenu class="mr-2" />
      </template>

      <template #button>
        <GwAddSessionDialog v-if="$route.name === 'GwSessionList'">
          <template #button="{ openDialog }">
            <v-btn
              fab
              absolute
              bottom
              right
              small
              :disabled="licenceExpired"
              color="success"
              @click="openDialog()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </GwAddSessionDialog>
        <GwAddSessionBulkDialog v-if="$route.name === 'GwSessionBulk'">
          <template #button="{ openDialog }">
            <v-btn
              fab
              absolute
              bottom
              right
              small
              :disabled="licenceExpired"
              color="success"
              @click="openDialog()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </GwAddSessionBulkDialog>

        <GwAddTranscriptionDialog v-if="$route.name === 'GwTranscriptions'">
          <template #button="{ openDialog }">
            <v-btn
              fab
              absolute
              bottom
              right
              small
              :disabled="licenceExpired"
              color="success"
              @click="openDialog()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </GwAddTranscriptionDialog>

        <GwGenerateJourneyDialog v-if="$route.name === 'GwJourneys'">
          <template #action="{ openDialog }">
            <v-tooltip left color="primary" attach="#test">
              <template #activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="success"
                    fab
                    id="test"
                    dark
                    small
                    absolute
                    right
                    bottom
                    @click="openDialog()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>PDF</span>
            </v-tooltip>
          </template>
        </GwGenerateJourneyDialog>
      </template>

      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <v-expand-transition>
      <GwTutorial
        v-show="
          showTutorial &&
          ['GwSessionList', 'GwSessionBulk'].includes($route.name)
        "
        class="mb-4"
        :mode="$route.name === 'GwSessionBulk' ? 'bulk' : 'default'"
        @close="showTutorial = false"
      />
    </v-expand-transition>

    <section>
      <router-view :key="$route.fullPath" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader";
import GwTutorial from "@/components/ghostwriter/GwTutorial";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import GwAddSessionDialog from "@/components/ghostwriter/GwAddSessionDialog";
import GwAddSessionBulkDialog from "@/components/ghostwriter/GwAddSessionBulkDialog";
import GwAddTranscriptionDialog from "@/components/ghostwriter/transcription/GwAddTranscriptionDialog";
import GwGenerateJourneyDialog from "@/components/ghostwriter/journeys/GwGenerateJourneyDialog";

export default {
  name: "GwSessionOverview",
  components: {
    ViewHeader,
    GwTutorial,
    GwCreditInfoMenu,
    ViewNavigation,
    GwAddSessionDialog,
    GwAddSessionBulkDialog,
    GwAddTranscriptionDialog,
    GwGenerateJourneyDialog,
  },
  data() {
    return {
      showTutorial: false,
      headers: [
        {
          text: this.$t("resources.users.status"),
          value: "status",
          width: "50px",
        },
        {
          text: this.$t("general.language"),
          value: "options.language",
          width: "50px",
        },
        {
          text: this.$t("resources.categories.name"),
          value: "name",
          width: "auto",
        },
        {
          text: this.$t("resources.nuggets.content"),
          value: "stats",
          width: "260px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    navItems() {
      var items = [
        {
          title: this.$t("gw.modes.single"),
          link: { name: "GwSessionList" },
        },
      ];
      if (process.env.VUE_APP_GHOSTWRITER_BULK === "true") {
        items.push({
          title: this.$t("gw.modes.bulk"),
          link: { name: "GwSessionBulk" },
        });
      }
      items.push({
        title: this.$t("gw.transcriptions.plural"),
        link: { name: "GwTranscriptions" },
      });
      if (process.env.VUE_APP_GHOSTWRITER_JOURNEY === "true") {
        items.push({
          /* title: `${this.$t("resources.journeys.journey.plural")} [beta]`, */
          title: `${this.$t("resources.journeys.journey.plural")}`,
          link: { name: "GwJourneys" },
          beta: true,
        });
      }
      if (["superadmin", "admin"].includes(this.role)) {
        items.push({
          title: this.$t("gw.licences.licence_administration"),
          link: { name: "GwManagement" },
        });
      }
      return items;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.ghostwriter"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
    if (
      !localStorage.getItem("gw_disable_tut") ||
      localStorage.getItem("gw_disable_tut") !== "true"
    ) {
      this.showTutorial = true;
    }
  },
  methods: {
    openTutorial() {
      if (localStorage.getItem("gw_disable_tut"))
        localStorage.removeItem("gw_disable_tut");
      this.showTutorial = true;
    },
  },
};
</script>
