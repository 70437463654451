<template>
  <v-dialog
    v-model="showDialog"
    width="640"
    scrollable
    :persistent="changed"
    @click:outside="!changed ? closeDialog() : null"
  >
    <v-card v-if="showDialog" :loading="loading" color="grey lighten-3">
      <template #progress>
        <v-progress-linear indeterminate color="white" height="6" />
      </template>
      <v-card-title class="overline">
        <!-- TODO: Update when more types possible -->
        <div class="mr-2">
          <v-tooltip
            v-if="content[idx].type === 'question'"
            right
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-img
                width="24"
                height="24"
                src="/img/question_type_1.png"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <span>{{ $t("resources.questions.types.6") }}</span>
          </v-tooltip>
          <v-icon v-if="content[idx].type === 'nugget'"> mdi-school </v-icon>
        </div>
        <span>{{
          content[idx].type === "question"
            ? $t("resources.questions.singular")
            : $t("resources.nuggets.singular")
        }}</span>
        <v-spacer />
        <div>
          <v-btn icon :disabled="idx <= 0 || changed" @click="idx--">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <span>{{ `${idx + 1}/${content.length}` }}</span>
          <v-btn
            icon
            :disabled="idx >= content.length - 1 || changed"
            @click="idx++"
          >
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
        </div>
        <v-btn icon :disabled="changed" @click="closeDialog()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="content">
        <v-expand-transition>
          <v-alert v-if="content[idx].is_accepted" type="success" text dense>
            {{ $t("gw.accept_hint") }}
          </v-alert>
        </v-expand-transition>
        <div v-if="content && content.length > 0">
          <GwQuestionForm
            v-if="content[idx].type === 'question'"
            :item="content[idx]"
            :disabled="loading || content[idx].is_imported || disabled"
            :idx="idx"
            :updated="updated"
            :show-options="true"
            hide-activate
            @valid="setValid"
            @changed="setChanged"
          />
          <GwNuggetForm
            v-if="content[idx].type === 'nugget'"
            :item="content[idx]"
            :disabled="loading || content[idx].is_imported || disabled"
            :idx="idx"
            :updated="updated"
            :show-options="true"
            hide-activate
            @valid="setValid"
            @changed="setChanged"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <!-- <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              color="secondary"
              class="mr-2"
              :disabled="
                content[idx].is_accepted || loading || changed || disabled
              "
              v-bind="attrs"
              v-on="on"
              @click="refreshItem(content[idx])"
            >
              <v-icon small> mdi-refresh </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("gw.refresh") }}</span>
        </v-tooltip> -->
        <!-- <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              color="secondary"
              class="mr-2"
              :disabled="loading"
              v-bind="attrs"
              v-on="on"
              @click="showOptions = !showOptions"
            >
              <v-icon small> mdi-cogs </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("gw.addSession.settings") }}</span>
        </v-tooltip> -->
        <v-spacer />
        <v-btn
          v-if="changed"
          small
          text
          color="error"
          :disabled="content[idx].is_imported || disabled"
          @click="resetForm()"
        >
          {{ $t("general.clear") }}
        </v-btn>
        <v-btn
          small
          depressed
          color="success"
          :loading="loading"
          :disabled="
            !content ||
            content.length == 0 ||
            disabled ||
            !valid ||
            content[idx].is_accepted ||
            loading ||
            !changed ||
            content[idx].is_imported
          "
          @click="save()"
        >
          {{ $t("general.save") }}
        </v-btn>
        <!-- <v-btn
          v-if="!changed"
          small
          depressed
          :text="!content[idx].is_accepted"
          :color="content[idx].is_accepted ? 'secondary' : 'secondary'"
          :disabled="
            !content ||
            content.length == 0 ||
            !valid ||
            changed ||
            content[idx].is_imported ||
            disabled
          "
          @click="toggleAccept()"
        >
          {{
            content[idx].is_accepted
              ? $t("gw.edit_content")
              : $t("gw.accept_content")
          }}
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import GwNuggetForm from "@/components/ghostwriter/content/GwNuggetForm";
import GwQuestionForm from "@/components/ghostwriter/content/GwQuestionForm";

export default {
  name: "GwBulkContentDialog",
  components: {
    GwNuggetForm,
    GwQuestionForm,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    content: {
      type: Array,
      required: false,
      default: () => [],
    },
    index: {
      type: [Number],
      required: false,
      default: 0,
    },
    sessionId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      idx: 0,
      valid: false,
      loading: false,
      changed: false,
      updated: false,
      //showOptions: true,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
  },
  watch: {
    visible(val) {
      this.idx = this.index;
      this.showDialog = val;
    },
    index(val) {
      this.idx = val;
      //this.showDialog = true;
    },
    idx(v) {
      //this.showOptions = false;
      this.$emit("index-change", v);
    },
    content: {
      handler(val) {
        if (!val || val.length === 0) this.closeDialog();
        this.updated = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "patch_content",
      //"delete_content",
      //"refresh_content",
      "fetch_bulk_session",
    ]),
    ...mapMutations("app", ["set_loading"]),
    closeDialog() {
      this.$emit("close");
    },
    setValid(val) {
      this.valid = val;
    },
    async save() {
      this.loading = true;
      let item = JSON.parse(JSON.stringify(this.content[this.idx]));
      /* if (item.type === "question") {
        if (item.content.defaultTime) {
          delete item.content.time;
        }
        delete item.content.defaultTime;
      } */
      item.content.time = Number(item.content.time)
        ? Number(item.content.time)
        : null;
      let props = [{ name: "content", value: item.content }];
      let payload = {
        id: item.id,
        type: `${item.type}s`,
        session_id: this.sessionId,
        session_type: "bulk",
        properties: props,
      };
      let res = await this.patch_content({
        payload: payload,
      });
      this.loading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.updated = true;
        this.changed = false;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    setChanged(val) {
      this.changed = val;
    },
    async resetForm() {
      this.loading = true;
      var res = await this.fetch_bulk_session({
        id: this.sessionId,
      });
      if (res && res._status === 200) this.setChanged(false);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.content-step {
  width: 15px;
  height: 3px;
  cursor: pointer;
}
</style>
