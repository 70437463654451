<template>
  <div>
    <ViewHeader :breadcrumbs="breadcrumbs" :title="title" icon="mdi-typewriter">
      <template #titleButton>
        <div class="d-flex align-center">
          <GwCreditInfoMenu />
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'GwSessionList' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading" />

    <section v-if="!loading" class="mt-4">
      <SectionHeader
        :title="$t('gw.parts')"
        :subtitle="$t('gw.sessionDetail.detailView')"
      >
        <template #action>
          <div class="d-flex align-center">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar tile size="24" v-bind="attrs" v-on="on">
                  <country-flag
                    :country="session.options.language.slice(-2)"
                    size="small"
                  />
                </v-avatar>
              </template>
              <span>{{
                $t("gw.selectedLanguage", {
                  lang: $t(`languages.${session.options.language}.display`),
                })
              }}</span>
            </v-tooltip>
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  class="mr-2"
                  :color="showOptions ? 'primary' : 'secondary'"
                  v-bind="attrs"
                  v-on="on"
                  @click="showOptions = !showOptions"
                >
                  <v-icon small> mdi-cogs </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("resources.invitations.settings") }}</span>
            </v-tooltip>
            <v-btn
              depressed
              small
              color="secondary"
              :to="{ name: 'GwSessionImport', params: { id: id } }"
            >
              <v-icon small class="mr-2"> mdi-import </v-icon>
              <span>{{ $t("gw.sessionDetail.importContent") }}</span>
            </v-btn>
          </div>
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-alert
              v-if="!licences.ghostwriter || licenceExpired"
              text
              type="error"
              dense
              border="left"
            >
              <span class="caption">{{ $t("gw.no_licence") }}</span>
            </v-alert>
            <GwSessionParts
              :parts="session.parts"
              :session-id="session.id"
              :options="session.options"
              :disabled="
                !licences.ghostwriter || licenceExpired || session.status !== 0
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <GwSessionOptions
      v-if="!loading"
      :session="session"
      :categories="categories"
      :visible="showOptions"
      :disabled="
        !licences.ghostwriter ||
        licenceExpired ||
        (session && session.status !== 0)
      "
      @close="showOptions = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import GwSessionParts from "@/components/ghostwriter/GwSessionParts";
import GwSessionOptions from "@/components/ghostwriter/GwSessionOptions";
import SectionHeader from "@/components/_layout/SectionHeader";
import CountryFlag from "vue-country-flag";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";

export default {
  name: "GwSessionDetail",
  components: {
    ViewHeader,
    ViewLoader,
    GwSessionParts,
    GwSessionOptions,
    SectionHeader,
    CountryFlag,
    GwCreditInfoMenu,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      showOptions: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    title() {
      if (!this.session || !this.session.name) return "Sessiondetails";
      return this.session.name;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.modes.single"),
          disabled: false,
          to: { name: "GwSessionList" },
        },
        {
          text: this.$t("general.details"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
  },
  destroyed() {
    this.set_session(null);
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_session"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("ghostwriter", ["set_session"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      if (!Number(this.id)) return false;
      this.loading = true;
      await this.fetch_categories();
      await this.fetch_session({ id: this.id });
      if (this.session.status !== 0)
        this.$router.replace({
          name: "GwSessionImport",
          params: { id: this.session.id },
        });
      if (this.session && this.session.disabled) {
        // no lang temp fix
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.invalid_session_error"),
        });
        this.$router.push({ name: "GwSessionList" });
      }
      this.loading = false;
    },
  },
};
</script>
