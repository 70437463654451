<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('gw.transcriptions.singular')"
      icon="mdi-typewriter"
    >
      <template #titleButton>
        <div class="d-flex align-center">
          <GwCreditInfoMenu />
          <v-btn
            x-small
            depressed
            exact
            color="grey lighten-2"
            class="ml-2"
            :to="{ name: 'GwTranscriptions' }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </div>
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading" />

    <div v-if="!loading" class="pt-4">
      <v-slide-y-transition hide-on-leave>
        <section v-if="!!transcription && transcription.status === 3">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card type="info" color="secondary" dark flat>
                  <v-card-title>
                    <v-icon class="mr-2">mdi-information-box</v-icon>
                    <span class="overline">{{ $t("gw.generateContent") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <p>
                      {{ $t("gw.transcription.generate_hint") }}
                    </p>
                    <div class="mb-2">
                      <span>{{ $t("gw.bulk.hints.generate_cost_1") }}</span>
                      <v-icon small class="ml-2 mr-1" color="#ffd700">
                        mdi-circle-multiple
                      </v-icon>
                      <span class="font-weight-bold">
                        {{
                          $t("gw.xCredits", {
                            number: transcription.credits.toLocaleString(),
                          })
                        }}
                      </span>
                      <span>{{ $t("gw.bulk.hints.generate_cost_2") }}</span>
                    </div>
                    <v-alert
                      v-if="
                        (!licences.ghostwriter.licence.can_overload &&
                          transcription.credits >
                            licences.ghostwriter.credits_count) ||
                        (licences.ghostwriter.licence.can_overload &&
                          transcription.credits >
                            licences.ghostwriter.credits_count +
                              licences.ghostwriter.licence.credits_overload)
                      "
                      type="info"
                      color="error"
                      dense
                      class="mb-0"
                    >
                      {{ $t("gw.bulk.hints.generate_cost_fail") }}
                    </v-alert>
                    <v-alert
                      v-if="
                        licences.ghostwriter.licence.can_overload &&
                        transcription.credits <=
                          licences.ghostwriter.licence.credits_overload +
                            licences.ghostwriter.credits_count &&
                        transcription.credits >
                          licences.ghostwriter.credits_count
                      "
                      type="info"
                      color="white"
                      icon="mdi-bank"
                      dense
                      text
                      class="mb-0"
                    >
                      {{ $t("gw.licences.credit_pool_usage_hint") }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="white"
                      depressed
                      small
                      class="secondary--text"
                      :loading="loading"
                      :disabled="
                        (!licences.ghostwriter.licence.can_overload &&
                          transcription.credits >
                            licences.ghostwriter.credits_count) ||
                        (licences.ghostwriter.licence.can_overload &&
                          transcription.credits >
                            licences.ghostwriter.credits_count +
                              licences.ghostwriter.licence.credits_overload)
                      "
                      @click="generateContent()"
                      >{{ $t("gw.generateContent") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-slide-y-transition>

      <v-expand-transition hide-on-leave>
        <section
          v-if="!!transcription && [1, 6].includes(transcription.status)"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card type="info" color="secondary" dark flat loading>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="12" sm="6" md="6" offset-md="1">
                        <h5>{{ $t("gw.patience_please") }}</h5>
                        <p>
                          {{ $t("gw.bulk.hints.generating_time") }}
                        </p>
                        <h5>{{ $t("gw.bulk.status.6_finished") }}</h5>
                        <div class="d-flex align-center">
                          <v-progress-linear
                            color="white"
                            stream
                            height="8"
                            indeterminate
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" class="text-center">
                        <v-avatar size="180" color="white">
                          <v-img src="@/assets/img/misc/ghostwriter.png" />
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-expand-transition>

      <v-expand-transition>
        <section
          v-if="
            (!!transcription && [4, 5].includes(transcription.status)) ||
            (!transcription && !loading)
          "
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-alert type="error" color="error" prominent>
                  <h5>{{ $t("general.createError") }}</h5>
                  <p>
                    {{ $t("gw.bulk.hints.generating_error") }}
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-expand-transition>

      <v-expand-transition>
        <div v-if="!!transcription && [7].includes(transcription.status)">
          <section>
            <SectionHeader
              :title="$t('resources.questions.sectionHeaders.overview.title')"
              :subtitle="
                transcription.description ? transcription.description : '-'
              "
            >
              <template #action>
                <div class="d-flex align-center">
                  <div
                    v-if="
                      !transcription.language ||
                      transcription.language.length === 2
                    "
                  >
                    LANG ERR ({{ transcription.language }})
                  </div>
                  <v-tooltip
                    v-if="
                      transcription.language &&
                      transcription.language.length > 4
                    "
                    left
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar tile size="24" v-bind="attrs" v-on="on">
                        <country-flag
                          :country="transcription.language.slice(-2)"
                          size="small"
                        />
                      </v-avatar>
                    </template>
                    <span>{{
                      $t("gw.selectedLanguage", {
                        lang: $t(`languages.${transcription.language}.display`),
                      })
                    }}</span>
                  </v-tooltip>
                </div>
              </template>
            </SectionHeader>

            <v-container>
              <v-row dense>
                <v-col cols="12" md="12">
                  <StatisticsCardSmall
                    :title="transcription.file_name"
                    :subtitle="$t('gw.transcriptions.file_name')"
                    icon="mdi-file-document"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <StatisticsCardSmall
                    :title="
                      transcription.created_at.toLocaleString($i18n.locale)
                    "
                    :subtitle="$t('resources.invitations.created_at')"
                    icon="mdi-clock"
                    color="grey lighten-3"
                    text-color="secondary"
                    icon-color="secondary"
                  />
                </v-col>
                <v-col v-if="transcription.file_id" cols="12" sm="6">
                  <StatisticsCardSmall
                    :title="transcription.file_id"
                    :subtitle="$t('gw.transcriptions.show_video')"
                    icon="mdi-youtube"
                    color="grey lighten-3"
                    text-color="secondary"
                    icon-color="error"
                    :href="`https://www.youtube.com/watch?v=${transcription.file_id}`"
                    href-target="_blank"
                  />
                </v-col>
              </v-row>
            </v-container>
          </section>

          <section>
            <SectionHeader
              :title="$t('gw.generated_content')"
              :subtitle="$t('gw.transcriptions.generated_from_file')"
            />
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card color="grey lighten-3" outlined>
                    <v-card-text>{{ transcription.transcription }}</v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-btn
                    depressed
                    small
                    color="secondary"
                    block
                    @click="copyTranscription(transcription)"
                  >
                    <span>{{ $t("gw.transcriptions.copy") }}</span>
                    <v-icon small class="ml-2">mdi-content-copy</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <GwAddSessionBulkDialog
                    :input-text="transcription.transcription"
                    :input-language="transcription.language"
                  >
                    <template #button="{ openDialog }">
                      <v-btn
                        depressed
                        small
                        :disabled="licenceExpired"
                        block
                        color="accent"
                        @click="openDialog()"
                      >
                        <span>{{ $t("gw.addSession.newSession") }}</span>
                        <v-icon small class="ml-2">mdi-typewriter</v-icon>
                      </v-btn>
                    </template>
                  </GwAddSessionBulkDialog>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <GwGenerateJourneyDialog
                    :input-text="transcription.transcription"
                    :input-language="transcription.language"
                    :input-title="transcription.file_name"
                  >
                    <template #action="{ openDialog }">
                      <v-btn
                        color="accent"
                        depressed
                        small
                        block
                        right
                        bottom
                        @click="openDialog()"
                      >
                        <span>{{ $t("gw.journey.generate.title") }}</span>
                        <v-icon small class="ml-2">mdi-typewriter</v-icon>
                      </v-btn>
                    </template>
                  </GwGenerateJourneyDialog>
                </v-col>
              </v-row>
            </v-container>
          </section>
        </div>
      </v-expand-transition>

      <v-slide-y-transition>
        <section
          v-if="!!transcription && [3, 4, 5, 7].includes(transcription.status)"
        >
          <SectionHeader
            :title="$t('ng.further_actions')"
            :subtitle="$t('ng.further_actions_descr')"
          />
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <GwDeleteTranscriptionDialog :transcription="transcription">
                  <template #button="{ openDialog }">
                    <v-btn
                      small
                      depressed
                      block
                      color="error"
                      @click="openDialog()"
                    >
                      <span>{{ $t("general.delete") }}</span>
                      <v-icon small class="ml-2">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </GwDeleteTranscriptionDialog>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import GwCreditInfoMenu from "@/components/ghostwriter/GwCreditInfoMenu";
import SectionHeader from "@/components/_layout/SectionHeader";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import GwAddSessionBulkDialog from "@/components/ghostwriter/GwAddSessionBulkDialog";
import GwDeleteTranscriptionDialog from "@/components/ghostwriter/transcription/GwDeleteTranscriptionDialog";
import CountryFlag from "vue-country-flag";
import GwGenerateJourneyDialog from "@/components/ghostwriter/journeys/GwGenerateJourneyDialog";

export default {
  name: "GwTranscriptionDetail",
  components: {
    ViewHeader,
    ViewLoader,
    GwCreditInfoMenu,
    SectionHeader,
    StatisticsCardSmall,
    GwAddSessionBulkDialog,
    GwDeleteTranscriptionDialog,
    CountryFlag,
    GwGenerateJourneyDialog,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: true,
      progress: null,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { transcription: "get_transcription" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("gw.transcriptions.plural"),
          disabled: false,
          to: { name: "GwTranscriptions" },
        },
        {
          text: this.$t("general.details"),
          disabled: true,
        },
      ];
    },
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
  },
  watch: {
    transcription: {
      handler: function (v) {
        if (v) this.checkStatus();
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.progress) this.progress = clearInterval(this.progress);
  },
  methods: {
    ...mapActions("ghostwriter", [
      "fetch_transcription",
      "fetch_gw_media_upload_token",
      "generate_transcription",
    ]),
    async fetchData() {
      if (!this.id) return false;
      var token = await this.fetch_gw_media_upload_token();
      var config = {
        headers: {
          gwtoken: token,
        },
      };
      var res = await this.fetch_transcription({
        id: this.id,
        config: config,
      });
      if (!res || res.msg === "item id deleted") {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
        this.$router.push({ name: "GwTranscriptions" });
      }

      this.checkStatus();
      this.loading = false;
    },
    async refreshSession() {
      if (![1, 2, 6].includes(this.transcription.status)) {
        this.progress = clearInterval(this.progress);
        return false;
      }
      await this.fetchData();
    },
    async checkStatus() {
      if (
        !this.transcription ||
        !!this.progress ||
        ![1, 2, 6].includes(this.transcription.status)
      )
        return false;
      this.progress = setInterval(
        function () {
          this.refreshSession();
        }.bind(this),
        6000
      );
    },
    copyTranscription(transcription) {
      navigator.clipboard.writeText(transcription.transcription);
      this.$notify({
        type: "success",
        title: this.$t("general.success"),
        text: this.$t("resources.invitations.linkCopied"),
      });
    },
    async generateContent() {
      var token = await this.fetch_gw_media_upload_token();
      var config = {
        headers: {
          gwtoken: token,
        },
      };
      var res = await this.generate_transcription({
        payload: {
          transcription_id: this.transcription.id,
        },
        config: config,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.fetchData();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
  },
};
</script>

<style></style>
