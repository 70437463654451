<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />

    <v-dialog v-model="showDialog" max-width="640" persistent scrollable>
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text">{{
          $t("gw.journey.delete.title")
        }}</v-card-title>
        <v-card-subtitle>
          {{ $t("gw.journey.delete.subtitle") }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn text small :disabled="loading" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            small
            color="error"
            :disabled="loading"
            @click="deleteJourney()"
          >
            {{ $t("general.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwDeleteJourneyDialog",
  props: {
    journey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("ghostwriter", ["update_gw_journey"]),
    closeDialog() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
    async deleteJourney() {
      this.loading = true;
      var payload = { id: this.journey.id, is_deleted: true };
      var res = await this.update_gw_journey({ payload: payload });
      this.loading = false;

      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
        });
        this.$router.push({ name: "GwJourneys" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
