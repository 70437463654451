<template>
  <div>
    <v-card color="grey lighten-3" flat>
      <v-card-text>
        <div v-if="!licences || licences.length === 0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >Keine verwaltbaren Lizenzen. *T</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-for="(licence, i) in licences" :key="`licence-${i}`">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ licence.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                :class="{ 'error--text': licence.is_expired }"
              >
                {{
                  licence.is_expired
                    ? $t("gw.licences.is_expired")
                    : licence.is_superadmin
                    ? $t("gw.licences.is_superadmin_hint")
                    : `${$t(
                        "gw.licences.date_end"
                      )}: ${licence.date_end.toLocaleDateString()}`
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <div>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ attrs, on }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      color="grey lighten-2"
                      small
                      class="mr-2"
                      label
                      min-width="200"
                    >
                      <v-icon small left color="secondary"
                        >mdi-circle-multiple</v-icon
                      >
                      {{ licence.credits_count.toLocaleString() }}</v-chip
                    >
                  </template>
                  <span>{{ $t("gw.licences.credit_count_hint") }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ attrs, on }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      color="grey lighten-2"
                      small
                      label
                    >
                      <v-icon small left color="secondary"
                        >mdi-account-multiple</v-icon
                      >
                      {{
                        licence.licences_total
                          ? `${licence.available_licence_count} (${licence.licences_total})`
                          : `${licence.licences_used} (${$t(
                              "gw.licences.unlimited"
                            )})`
                      }}
                    </v-chip>
                  </template>
                  <span>{{ $t("gw.licences.available_licences") }}</span>
                </v-tooltip>
              </div>
            </v-list-item-content>
            <v-list-item-content class="pl-4">
              <div>
                <v-tooltip
                  v-if="['superadmin'].includes(role)"
                  bottom
                  color="primary"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      :color="licence.is_superadmin ? 'primary' : 'secondary'"
                      >{{
                        licence.is_superadmin
                          ? "mdi-shield-crown"
                          : "mdi-shield-crown-outline"
                      }}</v-icon
                    >
                  </template>
                  <span>
                    {{
                      licence.is_superadmin
                        ? $t("gw.licences.types.superadmin")
                        : $t("gw.licences.types.prepaid")
                    }}</span
                  >
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="licence.credits_keep ? 'primary' : 'secondary'"
                      >{{
                        licence.credits_keep
                          ? "mdi-hand-coin"
                          : "mdi-hand-coin-outline"
                      }}</v-icon
                    >
                  </template>
                  <span>
                    {{
                      licence.credits_keep
                        ? $t("gw.licences.types.credit_keep_hint_1")
                        : $t("gw.licences.types.credit_keep_hint_2")
                    }}</span
                  >
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon v-bind="attrs" v-on="on" small class="mx-2">{{
                      ["month", 2].includes(licence.credits_period)
                        ? "mdi-calendar-month"
                        : "mdi-calendar"
                    }}</v-icon>
                  </template>
                  <span>
                    {{ $t("gw.licences.credits_period") }}:
                    {{
                      ["month", 2].includes(licence.credits_period)
                        ? $t("gw.licences.monthly")
                        : $t("gw.licences.annual")
                    }}</span
                  >
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="licence.can_overload ? 'primary' : 'secondary'"
                      >{{
                        licence.can_overload ? "mdi-bank" : "mdi-bank-off"
                      }}</v-icon
                    >
                  </template>
                  <span>
                    {{
                      licence.can_overload
                        ? $t("gw.licences.can_overload_hint_1")
                        : $t("gw.licences.can_overload_hint_2")
                    }}</span
                  >
                </v-tooltip>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex">
                <GwLicenceDialog :licence="licence">
                  <template #button="{ openDialog }">
                    <v-tooltip left color="secondary">
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            fab
                            x-small
                            color="secondary"
                            class="elevation-0"
                            @click="openDialog()"
                            ><v-icon small>mdi-account</v-icon></v-btn
                          >
                        </div>
                      </template>
                      <div>{{ $t("general.details") }}</div>
                    </v-tooltip>
                  </template>
                </GwLicenceDialog>

                <GhostwriterLicenceDialog
                  v-if="['superadmin'].includes(role)"
                  :licence="licence"
                >
                  <template #button="{ openDialog }">
                    <v-tooltip left color="secondary">
                      <template #activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            fab
                            x-small
                            class="ml-4 elevation-0"
                            color="secondary"
                            @click="openDialog()"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <div>{{ $t("general.edit") }}</div>
                    </v-tooltip>
                  </template>
                </GhostwriterLicenceDialog>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="i < licences.length - 1" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import GwLicenceDialog from "@/components/ghostwriter/GwLicenceDialog";
import GhostwriterLicenceDialog from "@/components/administration/ghostwriter/GhostwriterLicenceDialog";

export default {
  name: "GwLicenceList",
  components: {
    GwLicenceDialog,
    GhostwriterLicenceDialog,
  },
  props: {
    licences: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>

<style></style>
