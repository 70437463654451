<template>
  <div>
    <v-container>
      <v-card elevation="0" color="grey lighten-3">
        <v-form v-model="valid">
          <v-card-text>
            <small
              v-if="journey.status !== 'unpublished'"
              class="grey--text font-weight-bold"
              >{{ $t("resources.journeys.editWarning") }}</small
            >
            <v-row>
              <v-col cols="12" sm="6">
                <h5 class="secondary--text">
                  {{ $t("resources.journeys.propNames.cert_name") }}
                </h5>
                <v-select
                  v-model="input.certificate"
                  :items="visibleCertificates"
                  item-value="id"
                  return-object
                >
                  <template #item="{ item }">
                    <div class="d-flex align-center">
                      <span>
                        {{ item.name }}
                      </span>
                    </div>
                  </template>
                  <template #selection="{ item }">
                    <v-list-item>
                      <v-list-item-content>
                        {{ item.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              small
              color="error"
              :disabled="!inputChanged"
              @click="resetForm"
            >
              {{ $t("general.cancel") }}
            </v-btn>
            <v-btn
              text
              small
              color="success"
              :disabled="!inputChanged || !valid"
              @click="save"
            >
              {{ $t("general.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "JourneyCertificateForm",
  data() {
    return {
      input: null,
      valid: true,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      certificates: "get_certificates",
    }),
    inputChanged() {
      let changed = false;
      if (this.input.certificate.id !== this.journey.certificate.id)
        changed = true;

      return changed;
    },
    visibleCertificates() {
      if (!this.certificates) return [];
      var certificates = [...this.certificates];
      return certificates.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  beforeMount() {
    this.input = { ...this.journey };
  },
  destroyed() {
    this.set_certificates([]);
  },
  methods: {
    ...mapActions("journeys", ["patch_journey", "fetch_certificates"]),
    ...mapMutations("journeys", ["set_certificates"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_certificates();
      this.loading = false;
    },
    resetForm() {
      this.input = { ...this.journey };
    },
    async save() {
      let properties = [
        { name: "certificate_id", value: this.input.certificate.id },
      ];
      let payload = {
        journey_id: this.journey.id,
        language: this.$i18n.locale,
        properties: properties,
      };
      let res = await this.patch_journey({
        payload: payload,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.$emit("update");
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>

<style></style>
