<template>
  <v-form v-model="valid">
    <v-dialog
      v-model="showDialog"
      width="640"
      scrollable
      :persistent="changed"
      @click:outside="!changed ? closeDialog() : null"
    >
      <v-card v-if="showDialog" color="grey lighten-3">
        <v-card-title class="overline secondary--text">
          <!-- TODO: Update when more types possible -->
          <div class="mr-2">
            <v-icon>mdi-school</v-icon>
          </div>
          <span>{{ $t("resources.nuggets.singular") }}</span>
          <v-spacer />
          <v-btn icon @click="closeDialog()">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="nugget">
            <v-alert
              v-if="nugget.is_imported"
              color="info"
              prominent
              type="info"
              dense
              dark
            >
              <p class="mb-2">
                {{ $t("gw.content_imported") }}
              </p>
              <v-btn
                small
                outlined
                :to="{ name: 'NuggetDetail', params: { id: nugget.nugget_id } }"
              >
                {{ $t("gw.show_nugget") }}</v-btn
              >
            </v-alert>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  :background-color="disabled ? 'grey lighten-3' : 'white'"
                  :label="$t('resources.categories.singular')"
                  :items="categories"
                  v-model="inputNugget.content.category_id"
                  item-text="name"
                  item-value="id"
                  :color="
                    !!inputNugget.content.category_id ? 'primary' : 'error'
                  "
                  hide-details
                  :disabled="loading || disabled"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="inputNugget.content.title"
                  outlined
                  dense
                  :background-color="disabled ? 'grey lighten-3' : 'white'"
                  hide-details
                  :disabled="disabled"
                  :rules="inputNugget.formRules.title"
                  :label="$t('resources.nuggets.title')"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="inputNugget.content.description"
                  outlined
                  dense
                  rows="3"
                  :background-color="disabled ? 'grey lighten-3' : 'white'"
                  hide-details
                  :disabled="disabled"
                  :rules="inputNugget.formRules.description"
                  :label="$t('resources.nuggets.description')"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="inputNugget.content.body"
                  outlined
                  dense
                  rows="9"
                  :background-color="disabled ? 'grey lighten-3' : 'white'"
                  hide-details
                  :disabled="disabled"
                  :rules="inputNugget.formRules.body"
                  :label="$t('resources.nuggets.content')"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="changed"
            small
            text
            color="error"
            :disabled="disabled || loading"
            @click="resetForm()"
          >
            {{ $t("general.clear") }}
          </v-btn>
          <v-btn
            small
            text
            color="success"
            :loading="loading"
            :disabled="disabled || loading || !changed || !valid"
            @click="save()"
          >
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GwNugget } from "@/models/ghostwriter/content/GwNugget";

export default {
  name: "GwBulkNuggetDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    nugget: {
      type: GwNugget,
      required: true,
    },
    sessionId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultCategory: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      inputNugget: null,
      valid: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    changed() {
      var changed = false;
      if (!this.nugget || !this.inputNugget) return false;
      if (
        this.nugget.content.category_id !== this.inputNugget.content.category_id
      )
        changed = true;
      if (this.nugget.content.title !== this.inputNugget.content.title)
        changed = true;
      if (
        this.nugget.content.description !== this.inputNugget.content.description
      )
        changed = true;
      if (this.nugget.content.body !== this.inputNugget.content.body)
        changed = true;
      return changed;
    },
  },
  watch: {
    visible(val) {
      if (val) this.setNugget();
      this.showDialog = val;
    },
    content: {
      handler(val) {
        if (!val || val.length === 0) this.closeDialog();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["patch_content"]),
    ...mapMutations("app", ["set_loading"]),
    closeDialog() {
      this.$emit("close");
    },
    setNugget() {
      this.inputNugget = new GwNugget(JSON.parse(JSON.stringify(this.nugget)));
    },
    async save() {
      this.loading = true;
      let item = JSON.parse(JSON.stringify(this.inputNugget));
      delete item.content.time;
      delete item.content.defaultTime;
      let props = [{ name: "content", value: item.content }];
      let payload = {
        id: item.id,
        type: `nuggets`,
        session_id: this.sessionId,
        session_type: "bulk",
        properties: props,
      };
      let res = await this.patch_content({
        payload: payload,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async resetForm() {
      this.setNugget();
    },
    setDefaultCategory() {
      if (!this.defaultCategory) return false;
      this.inputNugget.content.category_id = this.defaultCategory;
    },
  },
};
</script>

<style lang="scss">
.content-step {
  width: 15px;
  height: 3px;
  cursor: pointer;
}
</style>
