<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-textarea
          :value="part.content"
          readonly
          outlined
          hide-details
          background-color="grey lighten-3"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row v-if="part.whole_content && part.whole_content.length > 0" dense>
          <v-col
            v-for="(obj, i) in part.whole_content"
            :key="i"
            cols="12"
            sm="6"
          >
            <GwContentListItem
              :item="obj"
              :session-id="part.session_id"
              :color="obj.is_accepted ? 'grey lighten-2' : 'grey lighten-2'"
              @click="openContent(i)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-tooltip
          top
          color="primary"
          :disabled="
            (!licences.ghostwriter.licence.can_overload &&
              licences.ghostwriter.credits_count >= part.set_credits) ||
            (licences.ghostwriter.licence.can_overload &&
              licences.ghostwriter.licence.credits_overload +
                licences.ghostwriter.credits_count >=
                part.set_credits &&
              part.whole_content.length == 0)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <ListButton
                :title="$t('gw.set')"
                :subtitle="$t('gw.xCredits', { number: part.set_credits })"
                icon="mdi-plus"
                :color="
                  part.whole_content.length > 0 ||
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.set_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.set_credits)
                    ? 'grey lighten-2'
                    : 'grey lighten-2'
                "
                :text-color="
                  part.whole_content.length > 0 ||
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.set_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.set_credits)
                    ? 'grey'
                    : 'primary'
                "
                :text-options="
                  part.whole_content.length > 0 ||
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.set_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.set_credits)
                    ? 'text--lighten-1'
                    : ''
                "
                :icon-color="
                  part.whole_content.length > 0 ||
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.set_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.set_credits)
                    ? 'grey lighten-1'
                    : 'primary'
                "
                click-type="link"
                :elevation="0"
                :disabled="
                  part.whole_content.length > 0 ||
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.set_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.set_credits)
                "
                @clicked="generateSet()"
              />
            </div>
          </template>
          <span>{{
            part.whole_content.length === 0
              ? $t("gw.not_enough_credits")
              : $t("gw.content_already_exist")
          }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-tooltip
          top
          color="primary"
          :disabled="
            (!licences.ghostwriter.licence.can_overload &&
              licences.ghostwriter.credits_count >= part.nugget_credits) ||
            (licences.ghostwriter.licence.can_overload &&
              licences.ghostwriter.licence.credits_overload +
                licences.ghostwriter.credits_count >=
                part.nugget_credits)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <ListButton
                :title="$t('resources.nuggets.singular')"
                :subtitle="$t('gw.xCredits', { number: part.nugget_credits })"
                icon="mdi-plus"
                :color="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.nugget_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.nugget_credits)
                    ? 'grey lighten-2'
                    : 'grey lighten-2'
                "
                :text-color="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.nugget_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.nugget_credits)
                    ? 'grey'
                    : 'primary'
                "
                :text-options="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.nugget_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.nugget_credits)
                    ? 'text--lighten-1'
                    : ''
                "
                :icon-color="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.nugget_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.nugget_credits)
                    ? 'grey lighten-1'
                    : 'primary'
                "
                click-type="link"
                :elevation="0"
                :disabled="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count < part.nugget_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.nugget_credits)
                "
                @clicked="generate('nuggets')"
              />
            </div>
          </template>
          <span>{{ $t("gw.not_enough_credits") }}</span>
        </v-tooltip>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-tooltip
          top
          color="primary"
          :disabled="
            (!licences.ghostwriter.licence.can_overload &&
              licences.ghostwriter.credits_count >= part.question_credits) ||
            (licences.ghostwriter.licence.can_overload &&
              licences.ghostwriter.licence.credits_overload +
                licences.ghostwriter.credits_count >=
                part.question_credits)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <ListButton
                :title="$t('resources.questions.singular')"
                :subtitle="$t('gw.xCredits', { number: part.question_credits })"
                icon="mdi-plus"
                :color="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count <
                      part.question_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.question_credits)
                    ? 'grey lighten-2'
                    : 'grey lighten-2'
                "
                :text-color="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count <
                      part.question_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.question_credits)
                    ? 'grey'
                    : 'primary'
                "
                :text-options="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count <
                      part.question_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.question_credits)
                    ? 'text--lighten-1'
                    : ''
                "
                :icon-color="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count <
                      part.question_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.question_credits)
                    ? 'grey lighten-1'
                    : 'primary'
                "
                click-type="link"
                :elevation="0"
                :disabled="
                  (!licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.credits_count <
                      part.question_credits) ||
                  (licences.ghostwriter.licence.can_overload &&
                    licences.ghostwriter.licence.credits_overload +
                      licences.ghostwriter.credits_count <
                      part.question_credits)
                "
                @clicked="generate('questions')"
              />
            </div>
          </template>
          <span>{{ $t("gw.not_enough_credits") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <GwContentDialog
      :content="part.whole_content"
      :index="contentIndex"
      :visible="showContent"
      :session-id="part.session_id"
      @close="showContent = false"
      @delete="removeItem"
      @refresh="refresh"
      @index-change="setIndex"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GwPart } from "@/models/ghostwriter/GwPart.js";
import GwContentDialog from "@/components/ghostwriter/GwContentDialog";
import GwContentListItem from "@/components/ghostwriter/GwContentListItem";
import ListButton from "@/components/_layout/ListButton";

export default {
  name: "GwSessionPart",
  components: {
    GwContentDialog,
    GwContentListItem,
    ListButton,
  },
  props: {
    part: {
      type: GwPart,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      contentIndex: 0,
      showContent: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_session" }),
    ...mapGetters("auth", { licences: "get_licences" }),
  },
  watch: {
    showDialog() {
      this.openContent(0);
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "generate_content",
      "generate_content_by_type",
      "delete_part",
    ]),
    ...mapMutations("app", ["set_loading"]),
    ...mapMutations("auth", ["set_gw_credits"]),
    openContent(idx) {
      if (!this.part.whole_content || !this.part.whole_content[idx])
        return false;
      this.contentIndex = idx;
      this.showContent = true;
    },
    async generate(type) {
      this.set_loading(true);
      //await new Promise(resolve => setTimeout(resolve, 2000));
      if (!type) return false;
      let payload = {
        part_id: this.part.id,
        session_id: this.part.session_id,
        type: type,
        language: this.session.options.language,
      };
      let res = await this.generate_content_by_type({
        payload: payload,
      });
      this.set_loading(false);
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.set_gw_credits(
          this.licences.ghostwriter.credits_count - res.credits
        );
        this.openContent(this.part.whole_content.length - 1);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    async generateSet() {
      this.set_loading(true);
      //await new Promise(resolve => setTimeout(resolve, 5000));
      let payload = {
        part_id: this.part.id,
        session_id: this.part.session_id,
        language: this.session.options.language,
        nuggets: true,
        questions: true,
      };
      let res = await this.generate_content({
        payload: payload,
      });
      this.set_loading(false);
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.set_gw_credits(
          this.licences.ghostwriter.credits_count - res.credits
        );
        this.openContent(0);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    removeItem(id) {
      let newIdx = this.contentIndex;
      if (this.part.whole_content.length > 0 && this.contentIndex > 0)
        newIdx = newIdx - 1 < 0 ? newIdx-- : 0;
      this.contentIndex = newIdx;
      this.part.removeItem(id);
    },
    refresh() {
      this.contentIndex = this.part.whole_content.length - 1;
    },
    setIndex(i) {
      this.contentIndex = i;
    },
  },
};
</script>
