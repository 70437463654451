import { Journeybundle } from "./Journeybundle";

export class Journey {
  id = -1;
  name = "";
  image = "";
  image_token = "";
  description = "";
  attempts = "";
  status = "";
  language = null;

  bundles = [];
  substructures = [];
  status = null;

  cert_image = "";
  cert_name = "";

  start_at = "";
  end_at = "";

  created_at = "";
  updated_at = "";

  coins = -1;

  creator_id = null;

  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.image = obj.image;
    this.image_token = obj.image_token;
    this.description = obj.description;
    this.attempts = obj.attempts;
    this.status = obj.status;
    this.language = obj.language;
    this.bundles = obj.bundles ? this.generateBundles(obj.bundles) : [];
    this.substructures = obj.substructure_ids;
    this.stats = obj.stats;
    this.start_at =
      obj.start_at == "0000-00-00 00:00:00" || !obj.start_at
        ? ""
        : obj.start_at.slice(0, 10);
    this.end_at =
      obj.end_at == "0000-00-00 00:00:00" || !obj.end_at
        ? ""
        : obj.end_at.slice(0, 10);
    this.created_at = new Date(obj.created_at);
    this.updated_at = new Date(obj.updated_at);

    this.coins = obj.coins ? obj.coins : 0;
    this.creator_id = obj.creator_id ? obj.creator_id : null;

    this.certificate = obj.certificate ? obj.certificate : null;
  }

  generateBundles(bundleInput) {
    let bundles = [];
    bundleInput.forEach((obj) => bundles.push(new Journeybundle(obj)));
    return bundles;
  }

  getImage() {
    if (!this.image_token)
      return require("@/assets/img/misc/default_small.jpg");
    return `${process.env.VUE_APP_MEDIA_SERVER_URL}/i/${this.image_token}`;
  }
}
